import axios from 'axios';
import { getAuthApiUrl } from '_shared/dataFetching/urlConfig';
import { logout } from '_shared/dataFetching/auth';
import { apiClient } from '_shared/dataFetching/apiConfig';
import { generateRandomNumber } from '_shared/utils/stringUtil';

export const AUTH_CLIENT_ID = 'tennis_collection';

export const authenticationService = {
  async validateCredentialsToken({ credentials }) {
    const formData = new URLSearchParams();
    formData.append('grant_type', 'authorisation');
    formData.append('user_name', credentials.userName);
    formData.append('password', credentials.password);
    formData.append('client_id', AUTH_CLIENT_ID);
    const url = `${getAuthApiUrl()}token`;
    const res = await axios({
      method: 'post',
      url,
      data: formData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    const data = res.data;
    return data;
  },
  async logout() {
    return logout();
  },
  async getUserPermissions() {
    const url = `user/perms?unique_param=${generateRandomNumber()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getAblyToken() {
    const url = `realtime/ably/access_token`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  }
};
